import {Button, Flex, Form, Input} from "antd";
import React from "react";
import {Fields, RemoveEvent} from "./types";
import { DeleteOutlined } from "@ant-design/icons";

type FieldTemplateProps = {
    fieldName: Fields;
    name: string;
    placeholder: string;
    removeEvent: RemoveEvent
}

const FieldTemplate: React.FC<FieldTemplateProps> = ({fieldName, placeholder, name, removeEvent}) => {
    return <Flex align={"center"} gap={10}>
    <Form.Item label={fieldName} name={name} style={{flex: 1}}>
        <Input placeholder={placeholder}/>
    </Form.Item>
        <Button style={{marginBottom: "-5px"}} onClick={(e: React.MouseEvent) => {removeEvent(fieldName)}} icon={<DeleteOutlined />} />
    </Flex>
}

export default FieldTemplate