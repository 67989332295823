import React, {useState} from 'react';
import {Form, Input, Tag, Typography, Upload, Button, Spin, message} from 'antd';
import styled from '@emotion/styled';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import useFields from "../hooks/useFields";

type RequiredMark = boolean | 'optional' | 'customize';

const customizeRequiredMark = (label: React.ReactNode, {required}: { required: boolean }) => (
    <>
        {required ? <Tag color="error">Required</Tag> : <Tag color="warning">optional</Tag>}
        {label}
    </>
);

const StyledFormItemsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 2vw;
    align-items: center;

    > * {
        align-self: center;
    }
`;

const StyledFormContainer = styled.div`
    padding: 3vw;
    position: relative;
`;

const StyledButton = styled(Button)`
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background-color: #45a049;
    }
`;

const SpinnerOverlay = styled.div`
    display: ${({isLoading}: { isLoading: boolean }) => (isLoading ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const downloadPDF = async (htmlContent: string) => {
    try {
        const response = await fetch('/api/makepdf', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ html: htmlContent }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'out.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};


const MainForm: React.FC<{ setResult: React.Dispatch<React.SetStateAction<string>> }> = ({setResult}) => {
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>();
    const [isLoading, setIsLoading] = useState(false);
    const [photo, setPhoto] = useState<any>("");
    const [pdf, setPdf] = useState<any>("");
    const [htmlContent, setHtmlContent] = useState("");
    const {components, toolbar, removeField} = useFields([])


    const onRequiredTypeChange = ({requiredMarkValue}: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const values = await form.validateFields();

            const formattedData = {
                model: "mistral:7b",
                messages: [{
                    message: JSON.stringify(values)
                }]
            };

            console.log("Submitting data:", JSON.stringify(formattedData));

            const res = await axios.post('/api/chat', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const htmlResult = res.data;
            setResult(htmlResult);
            setHtmlContent(htmlResult);

            console.log(res);

            message.success('Form submitted successfully!');
        } catch (error: any) {
            console.error("Submission error:", error);
            if (axios.isAxiosError(error)) {
                console.error("Axios error details:", error.response?.data);
                message.error(`Form submission failed! ${error.response?.data?.message || error.message}`);
            } else {
                message.error(`Form submission failed! ${error.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handlePhotoChange = ({fileList}: any) => {
        if(fileList.length > 0)
            setPhoto(fileList[0]);
    };
    const handlePdfChange = ({fileList}: any) =>{
        if(fileList.length > 0)
          setPdf(fileList[0]);
    };

    const handlePdfUpload = async () => {
        if (pdf.length === 0) {
            message.error("Please select a PDF file first!");
            return;
        }

        const formData = new FormData();
        formData.append("file", pdf.originFileObj);

        try {
            setIsLoading(true);
            const response = await axios.post('https://test.boombug.ru/api/parsepdf', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const extractedText = response.data;
            console.log(extractedText)
            setResult(extractedText);
            setHtmlContent(extractedText);
            message.success("PDF uploaded and parsed successfully!");
        } catch (error) {
            console.error("Error uploading PDF:", error);
            message.error("Failed to upload and parse the PDF.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <StyledFormContainer>
            <SpinnerOverlay isLoading={isLoading}>
                <Spin size="large"/>
            </SpinnerOverlay>
            <Typography.Title level={3}>Personal Details</Typography.Title>
            <Form
                form={form}
                layout="vertical"
                initialValues={{requiredMarkValue: 'optional'}}
                onValuesChange={onRequiredTypeChange}
                requiredMark={requiredMark === 'customize' ? customizeRequiredMark : requiredMark}
            >
                <StyledFormItemsContainer>
                    <Form.Item label="Wanted Job Title" tooltip="Some text" name="jobTitle" rules={[{required: true}]}>
                        <Input placeholder="e.g. Teacher"/>
                    </Form.Item>
                    <Form.Item label="Upload photo" valuePropName="fileList"
                               getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}>
                        <Upload
                            // action="/upload.do"
                            listType="picture-card"
                            fileList={photo? [photo] : []}
                            onChange={handlePhotoChange}
                            maxCount={1}
                        >
                            {photo.length >= 1 ? null : (
                                <div>
                                    <PlusOutlined/>
                                    <div style={{marginTop: 8}}>Upload</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                    <Form.Item label="First Name" name="firstName" rules={[{required: true}]}>
                        <Input placeholder="John Doe"/>
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{required: true, type: 'email'}]}>
                        <Input placeholder="example@exmail.com"/>
                    </Form.Item>
                    <Form.Item label="Country" name="country" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Last Name" name="lastName" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Phone" name="phone" rules={[{
                        required: true,
                        pattern: /^\d{10,15}$/,
                        message: 'Please enter a valid phone number'
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="City" name="city" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    {components}
                </StyledFormItemsContainer>
                <Typography.Title level={3}>Professional Summary</Typography.Title>
                <Form.Item label="Some description" name="description" rules={[{required: true}]}>
                    <Input.TextArea/>
                </Form.Item>
                <StyledButton type="default" onClick={handleSubmit}>
                    Submit
                </StyledButton>
                <StyledButton type="default" onClick={() => downloadPDF(htmlContent)}>
                    Download
                </StyledButton>
                <Form.Item label="Upload resume pdf" valuePropName="fileList"
                           getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}>
                    <Upload
                        // action="/upload.do"
                        accept=".pdf"
                        fileList={pdf? [pdf] : []}
                        onChange={handlePdfChange}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Select resume PDF</Button>
                    </Upload>
                </Form.Item>

                <StyledButton type="default" onClick={handlePdfUpload} disabled={[pdf].length === 0}>
                    Upload PDF
                </StyledButton>
            </Form>
            {toolbar}
        </StyledFormContainer>
    );
};

export default MainForm;


