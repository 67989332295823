import styled from "@emotion/styled";
import React from "react";

const StyledResultContainer = styled.div`padding: 3vw`
const StyledResultContent = styled.div`height: 100%; width: 100%; box-shadow: 0 0 10px rgba(0,0,0,0.5); border-radius: 10px;`

const Result: React.FC<{value?: string}> = ({value}) => {
    return <StyledResultContainer><StyledResultContent dangerouslySetInnerHTML={{__html: value || ""}}></StyledResultContent></StyledResultContainer>
}

export default Result