import styled from "@emotion/styled";
import React from "react";

const StyledPageLayout = styled.div`
display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
`

type PageLayoutProps = {children: React.ReactNode}

const PageLayout:React.FC<PageLayoutProps> =    ({children}) => {
    return <StyledPageLayout>{children}</StyledPageLayout>
}
export default PageLayout;